<template>
    <Card class="p-3 text-darkPurple flex flex-col justify-between gap-5">
            <h2 class="font-bold text-lg">{{ title }}</h2>

            <p class="text-base text-jet font-normal">
                {{ `These settings will automatically apply to newly ${type} in Cloudenly Admin and ESS` }}
            </p>

            <div class="flex gap-2">
                <SwitchToggle v-model="overrideModel" :value="overrideModel" @input="toggleOverride($event)"/>
                <p class="text-sm font-normal">
                    <span>Admins can override these settings when creating </span> 
                    <span class="lowercase">{{ name}}</span>.
                </p>
            </div>

            <p class="uppercase text-blueCrayola text-xs font-black">{{ `${name} Visibility Settings` }}</p>
            <checkbox
                checkbox-size="height:16px; width:16px; margin-top: 3px; cursor: pointer"
                @change="handleChange($event)"
                :label="visibilityLabel"
                v-model="visibleModel"
            />

            <p class="uppercase text-blueCrayola text-xs font-black">{{ `${name} Permissions` }}</p>

            <div class="w-full">
                <div class="flex gap-2">
                    <SwitchToggle v-model="restrictModel" />
                    <p class="text-sm font-normal">
                        <span>Restrict actions within </span> 
                        <span class="lowercase">{{ name}} to:</span>.
                    </p>
                </div>

                <div class="width h-auto mx-auto px-3 border-l border-dashed border-romanSilver flex flex-wrap justify-start items-center gap-3">
                    <div v-for="(action, index) in  restrictActions" :key="index">
                        <checkbox
                            checkbox-size="height:16px; width:16px; margin-top: 3px; cursor: pointer"
                            @change="handleRestriction($event, action)"
                            :label="action.name"
                            v-model="action.isChecked"
                        />
                    </div>
                </div>
            </div>

            <checkbox v-if="name === 'Files'"
                checkbox-size="height:16px; width:16px; margin-top: 3px; cursor: pointer"
                @change="handleFileOwner($event)"
                label="File owners are exempted from these restrictions."
                v-model="fileOwnerModel"
            />
            
            <div class="line" />
    </Card>
</template>
  
<script>
export default {
    name: "Permissions",
    components: {
        Card: () => import('@/components/Card'),
        SwitchToggle: () => import('@/components/Switch'),
        Checkbox: () => import("@/components/Checkbox")
    },
    props: {
        name: String,
        type: String,
        title: String,
        allowOverride: Boolean,
        restrictSwitch: Boolean,
        visibilityLabel: String,
        isVisible: Boolean,
        isFileOwner: Boolean,
        restrictActions: Array
    },
    data() {
        return {
            overrideModel: this.type === "created folders" ? this.allowOverride : this.allowOverride,
            restrictModel: this.type === "created folders" ? this.restrictSwitch : this.restrictSwitch,
            visibleModel: this.type === "created folders" ? this. isVisible : this.isVisible,
            fileOwnerModel: this.isFileOwner
        };
    },
   
    methods: {
        toggleOverride(event) {
            this.$emit("input", event, this.type);
        },
        handleChange(event){
            this.$emit("change", event, this.type);
        },
        handleRestriction(event, action){
            this.$emit("restriction", action, this.type);
        },
        handleFileOwner(event){
            if(this.name === 'Files'){
                this.$emit("owner", event);
            }
        }
    },
};
</script>

<style scoped>
.line{
  border: 0.3px solid  #878E99;
  width: 100%;
  height: 0;
  margin: auto;
  padding: 0;
}

.width{
    width: 97%;
}
</style>